import React from "react";
import HomeSecondaryTitle from "./home-secondary-title";

// HOME COMPONENT - Our services display
const HomeOurServices = (props: {
  targetRef: any,         // ->   reference from home page
  textContent: any | undefined
}) => {

  const servicesList = props?.textContent && [
    props.textContent.service01,
    props.textContent.service02,
    props.textContent.service03
  ];
  
  return (
    <div className="flex flex-col min-h-screen px-4 mb-40 lg:px-32 md:px-16" ref={props?.targetRef && props.targetRef}>
      <div className="sm:mt-7 lg:mb-14 2xl:my-20">
        <HomeSecondaryTitle 
          section={3}
          smallTitle={props?.textContent && props.textContent?.title} 
          mainContent={props?.textContent && props.textContent?.description}/>
      </div>
      {/* WITHOUT MOBILE VERSION [NEW VERSION | 3 Rows] */}
      <div className="flex flex-col w-full h-full lg:flex-row 2xl:flex-col lg:space-x-28 2xl:space-x-0">
        { servicesList && 
          servicesList.map((service: any, index: number) => (
            <div className="flex flex-col pt-16 2xl:border-t 2xl:flex-row border-cardinal-white lg:pt-0 lg:w-1/3 2xl:w-full" key={index + '_our_services'}>
              <div className="flex pb-3 text-2xl font-medium sm:pb-5 2xl:h-full 2xl:w-2/5 2xl:text-4xl 2xl:pt-12 2xl:pl-6" key={index + '_our_services_title'}>
                { service.serviceTitle }
              </div>
              <div className={`flex flex-col h-full text-base font-light 2xl:w-3/5 2xl:pt-12 xl:text-2xl`}
                placeholder={`${index == 2 ? '*Make it bold, make it magic*' : ''}`}
                key={index + '_description_our_services'}>
                <span className="h-full 2xl:pr-20 pb-9 2xl:pb-0" key={index + '_description_our_services_s'}>
                  { service.serviceDescription }
                </span>
                <div className="flex flex-wrap border-t 2xl:border-t-0 border-cardinal-white 2xl:pt-20 2xl:pb-28" key={index + '_voices_our_services'}>
                  {
                    service.listo && service.listo.map((voice: any, index: number) => (
                      <div className={`flex justify-start w-full py-4 text-base 2xl:text-2xl border-b 2xl:border-b-0 2xl:w-1/2 lg:py-5 ${index == 3 && 'sm:hidden 2xl:flex'}`} key={index + '_link_label_our_services'}>
                        <span key={index + '_link_label_our_services_s'}>
                          { voice }
                        </span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default HomeOurServices;
