export const isRunningOnWeb = () => {
  return typeof window !== 'undefined'// && window.localStorage;
}

export const isMobileDevice = () => {
  const userAgent: string = isRunningOnWeb() && typeof window.navigator !== "undefined" ? navigator.userAgent : "";
  const _isMobile: boolean = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );

  return _isMobile;
}

export const getSocialLinkBySocialName = (socialName: string): string => {
  if (socialName) {
    if (socialName.includes("facebook")) {
      return "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
    }
    if (socialName.includes("instagram")) {
      return "https://www.instagram.com/cardinal.web3/";
    }
    if (socialName.includes("linkedin")) {
      return "https://www.linkedin.com/company/cardinalsolar/";
    }
    return "";
  }
  return "";
}

export const getLinkByChosenName = (chosenName: string): string => {

  if (chosenName) {

    if (chosenName.includes("hitachi")) {
      return "https://www.hitachi.eu/";
    }

    if (chosenName.includes("freename")) {
      return "https://freename.io/";
    }

    if (chosenName.includes("cloudname")) {
      return "https://cloudname.com/";
    }

    if (chosenName.includes("youngplatform")) {
      return "https://youngplatform.com/";
    }

  }

  return "";

}

export const getUserPreferredLocale = (): string => {
  const userLocale: string = isRunningOnWeb() && navigator ? (navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language) : '';
  return userLocale;
}